import React from 'react'
import { v4 } from 'uuid'
import { graphql } from 'gatsby'

import { Image } from '../../ui'

import LayoutRoom from '../../components/layoutRoom'
import { useTranslation } from 'gatsby-plugin-react-i18next'

function JuniorSuitePage({ data }) {
  const { t } = useTranslation()
  const room = t('suites:rooms', { returnObjects: true })[0]

  return (
    <LayoutRoom
      images={data.gallery.edges.map((image) => (
        <Image key={v4()} fixed={image.node.childImageSharp.fixed} layout="background" />
      ))}
      roomType={room.roomType}
      title={room.title}
      desc={room.desc}
      amenities={t('suites:amenities', { returnObjects: true })}
    />
  )
}

export const query = graphql`
  query {
    gallery: allFile(
      filter: { relativeDirectory: { eq: "suites/standard" } }
      sort: { fields: name }
    ) {
      ...multipleFixedImage
    }
  }
`

export default JuniorSuitePage
